export default function Features() {
    return (
        <div id="features" className="mt-48 md:mt-64">
            <div className="text-center  mb-24 px-6">
                <p className="sm:text-[18px] text-dprpl font-bold leading-5 tracking-[1.8px]">
                    FEATURES
                </p>
                <p className="text-4xl sm:text-5xl font-bold sm:leading-[60px] text-[#170F49] mt-4 sm:mt-7">
                    Premium Features Await
                </p>
                <p className="text-[#6F6C90] font-[18px] leading-[30px] mt-3 max-w-[600px] mx-auto">
                    Discover a world of excellence with POS44's premium features. Elevate your
                    experience and unlock unparalleled capabilities for ultimate success.
                </p>
            </div>
            <div className="max-w-[786px] xl:max-w-[1200px] mx-auto md:grid md:grid-cols-2 xl:grid-cols-3 gap-6 px-6 md:px-0 ">
                <div className="mb-6 md:mb-0 col-span-2 w-full border-2 border-dashed border-[#7253EF] p-8 rounded-3xl transition-all hover:bg-[#F7F6FB]">
                    <p className="bg-gradient-to-r from-[#FADE15] to-[#FAC815] w-max p-3 mb-4 rounded-xl text-[17px] leading-[16px] tracking-[0.68px]">
                        INVENTORY MANAGEMENT
                    </p>
                    <p className="text-[#586174] leading-[28px] tracking-[-0.18px]">
                        <p>Control stock and track merchandise turnover is crucial. POS44 helps;</p>
                        <ul className="ml-5">
                            <li>
                                Understand which items are selling well and which are not, which
                                helps plan shipments and reduce out of stock items.
                            </li>
                            <li>
                                Keep track of inventory and order new consignments on time to avoid
                                disrupting the supply chain and lose of customers.
                            </li>
                            <li>
                                Respond quickly to changes in demand, make adjustments to plans and
                                adapt to new conditions.
                            </li>
                            <li>Reduce inventory costs by stock optimization.</li>
                        </ul>
                    </p>
                </div>
                <div className="mb-6 md:mb-0 w-full md:w-[381px] border-2 border-dashed border-[#7253EF] p-8 rounded-3xl transition-all hover:bg-[#F7F6FB]">
                    <p className="bg-gradient-to-r from-[#FADE15] to-[#FAC815] w-max p-3 mb-4 rounded-xl text-[17px] leading-[16px] tracking-[0.68px]">
                        FINANCIAL ACCOUNTING
                    </p>
                    <p className="text-[#586174] leading-[28px] tracking-[-0.18px]">
                        Every cent is under control. Cost analysis and keeping records of debtors
                        are vital for the successful development of a business. It is easy to
                        control cash flows and identify unnecessary expenses with pos44.
                    </p>
                </div>
                <div className="mb-6 md:mb-0 w-full md:w-[381px] border-2 border-dashed border-[#7253EF] p-8 rounded-3xl transition-all hover:bg-[#F7F6FB]">
                    <p className="bg-gradient-to-r from-[#FADE15] to-[#FAC815] w-max p-3 mb-4 rounded-xl text-[17px] leading-[16px] tracking-[0.68px]">
                        POS
                    </p>
                    <p className="text-[#586174] leading-[28px] tracking-[-0.18px]">
                        Add order in 3 taps. Scan SKU, add payment and print receipt in seconds to
                        have more free time to take care of customers.
                    </p>
                </div>
                <div className="mb-6 md:mb-0 w-full md:w-[381px] border-2 border-dashed border-[#7253EF] p-8 rounded-3xl transition-all hover:bg-[#F7F6FB]">
                    <p className="bg-gradient-to-r from-[#FADE15] to-[#FAC815] w-max p-3 mb-4 rounded-xl text-[17px] leading-[16px] tracking-[0.68px]">
                        E-CATALOGUE, LANDING PAGE
                    </p>
                    <p className="text-[#586174] leading-[28px] tracking-[-0.18px]">
                        Landing page and online store are already included. Customers can place an
                        order online
                    </p>
                </div>
                <div className="mb-6 md:mb-0 w-full md:w-[381px] border-2 border-dashed border-[#7253EF] p-8 rounded-3xl transition-all hover:bg-[#F7F6FB]">
                    <p className="bg-gradient-to-r from-[#FADE15] to-[#FAC815] w-max p-3 mb-4 rounded-xl text-[17px] leading-[16px] tracking-[0.68px]">
                        COURIER APPLICATION
                    </p>
                    <p className="text-[#586174] leading-[28px] tracking-[-0.18px]">
                        Couriers can see tasks on a smartphone. All order information such as
                        customer address, contact details, items list and payment status are
                        available on the go.
                    </p>
                </div>
                <div className="mb-6 md:mb-0 w-full md:w-[381px] border-2 border-dashed border-[#7253EF] p-8 rounded-3xl transition-all hover:bg-[#F7F6FB]">
                    <p className="bg-gradient-to-r from-[#FADE15] to-[#FAC815] w-max p-3 mb-4 rounded-xl text-[17px] leading-[16px] tracking-[0.68px]">
                        BUSSINESS ANALYTICS
                    </p>
                    <p className="text-[#586174] leading-[28px] tracking-[-0.18px]">
                        Detailed reports on company activities will help assess the state and
                        determine the necessary managerial decisions for further growth and
                        development.
                    </p>
                </div>
                <div className="flex flex-col justify-between w-full md:w-[381px] bg-[#F7F6FB] p-8 rounded-3xl">
                    <div>
                        <p className=" text-[24px] leading-[32px] tracking-[0.36px] mb-4">
                            Explore Demo Account
                        </p>
                        <p className="text-[#586174] leading-[28px] tracking-[-0.18px]">
                            Explore Demo Account Now!
                        </p>
                    </div>
                    <a href="https://pos44.tech/user/register" className="no-underline">
                        <div className="cursor-pointer w-max text-black transition-all duration-500 bg-gradient-to-r from-[#FADE15] to-[#FAC815] bg-size-100 bg-pos-0 hover:bg-size-200 hover:bg-pos-100 rounded-full px-6 py-3 tracking-[-0.3px] leading-[24px] font-normal">
                            Try For Free
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
}
