export default function Navbar() {
    const openMobileNavbar = () => {
        const modal = document.getElementById("mob-menu");
        if (modal.style.display == "none") {
            modal.style.display = "flex";
            modal.style.pointerEvents = "all";
        } else {
            modal.style.display = "none";
            modal.style.pointerEvents = "none";
        }
    };
    const closeModal = () => {
        const modal = document.getElementById("mob-menu");
        modal.style.display = "none";
        modal.style.pointerEvents = "none";
    };
    return (
        <>
            <div
                id="mob-menu"
                className="text-center hidden justify-center items-center bg-white bg-opacity-70 bg w-full h-full fixed left-0 top-0 z-20 backdrop-blur-xl"
            >
                <div className="flex flex-col gap-8 text-4xl font-medium">
                    <a
                        href="https://pos44.tech/user/login"
                        className="sm:hidden text-2xl py-1 no-underline text-dprpl tracking-[-0.3px] leading-[24px] transition-all hover:text-blck"
                    >
                        Sign In
                        <span className="h-[1px] w-10 mx-auto bg-black bg-opacity-30 block mt-10"></span>
                    </a>
                    <a
                        onClick={closeModal}
                        href="/"
                        className="text-2xl py-1 no-underline text-blck tracking-[-0.3px] leading-[24px] transition-all hover:text-dprpl"
                    >
                        Main
                    </a>
                    <a
                        onClick={closeModal}
                        href="#features"
                        className="text-2xl py-1 no-underline text-blck tracking-[-0.3px] leading-[24px] transition-all hover:text-dprpl"
                    >
                        Features
                    </a>
                    <a
                        onClick={closeModal}
                        href="#pricing"
                        className="py-1 text-2xl no-underline text-blck tracking-[-0.3px] leading-[24px] transition-all hover:text-dprpl"
                    >
                        Pricing
                    </a>
                    <a
                        onClick={closeModal}
                        href="#contact"
                        className="py-1 text-2xl no-underline text-blck tracking-[-0.3px] leading-[24px] transition-all hover:text-dprpl"
                    >
                        Support
                    </a>
                    {/* <a
                        onClick={closeModal}
                        href="https://x2pos.com/partners"
                        className="py-1 text-2xl no-underline text-blck tracking-[-0.3px] leading-[24px] transition-all hover:text-dprpl"
                    >
                        Affiliate
                    </a> */}
                    <a
                        onClick={closeModal}
                        href="#contact"
                        className="py-1 text-2xl no-underline text-blck tracking-[-0.3px] leading-[24px] transition-all hover:text-dprpl"
                    >
                        Contacts
                    </a>
                </div>
            </div>
            <div className="shadow-sm lg:hidden fixed top-0 w-full flex justify-between items-center py-4 px-6 backdrop-blur-lg z-20 bg-white bg-opacity-30">
                <div className="flex items-center flex-1">
                    {/* <img className="w-10" src="logo.png" alt="" /> */}
                    <span className="text-blck text-2xl font-bold">POS44</span>
                </div>
                <div className="flex items-center justify-end mr-8">
                    <a
                        href="https://pos44.tech/user/login"
                        className="hidden sm:inline px-6 py-3 tracking-[-0.3px] leading-[24px] font-medium text-dprpl no-underline"
                    >
                        Sign In
                    </a>
                    <a href="https://pos44.tech/user/register" className="no-underline">
                    <div className="cursor-pointer transition-all duration-500 bg-gradient-to-r from-prpl to-dprpl bg-size-100 bg-pos-0 hover:bg-size-200 hover:bg-pos-100 text-white rounded-full px-6 py-3 tracking-[-0.3px] leading-[24px] font-normal">
                        Try For Free
                    </div>
                    </a>
                </div>
                <img
                    onClick={openMobileNavbar}
                    src="menu.png"
                    alt=""
                    className="h-8"
                />
            </div>
            <div className="shadow-sm hidden lg:flex fixed top-0 max-w-[1440px] w-full justify-between items-center py-5 px-12 backdrop-blur-lg z-20 bg-white bg-opacity-30">
                <div className="flex items-center w-[240px]">
                    <img className="w-10" src="logo.png" alt="" />
                    <span className="text-blck text-3xl font-bold  ml-3">
                        POS44
                    </span>
                </div>
                <div className="flex gap-2">
                    <a
                        href="/"
                        className="px-4 py-2 no-underline text-blck tracking-[-0.3px] leading-[24px] transition-all hover:text-dprpl"
                    >
                        Main
                    </a>
                    <a
                        href="#features"
                        className="px-4 py-2 no-underline text-blck tracking-[-0.3px] leading-[24px] transition-all hover:text-dprpl"
                    >
                        Features
                    </a>
                    <a
                        href="#pricing"
                        className="px-4 py-2 no-underline text-blck tracking-[-0.3px] leading-[24px] transition-all hover:text-dprpl"
                    >
                        Pricing
                    </a>
                    <a
                        href="#contact"
                        className="px-4 py-2 no-underline text-blck tracking-[-0.3px] leading-[24px] transition-all hover:text-dprpl"
                    >
                        Support
                    </a>
                    {/* <a
                        href="https://x2pos.com/partners"
                        className="px-4 py-2 no-underline text-blck tracking-[-0.3px] leading-[24px] transition-all hover:text-dprpl"
                    >
                        Affiliate
                    </a> */}
                    <a
                        href="#contact"
                        className="px-4 py-2 no-underline text-blck tracking-[-0.3px] leading-[24px] transition-all hover:text-dprpl"
                    >
                        Contacts
                    </a>
                </div>
                <div className="flex items-center justify-end w-[240px]">
                    <a
                        href="https://pos44.tech/user/login"
                        className="px-6 py-3 tracking-[-0.3px] leading-[24px] font-medium text-dprpl no-underline"
                    >
                        Sign In
                    </a>
                    <a href="https://pos44.tech/user/register" className="no-underline">

                    <div className="cursor-pointer transition-all duration-500 bg-gradient-to-r from-prpl to-dprpl bg-size-100 bg-pos-0 hover:bg-size-200 hover:bg-pos-100 text-white rounded-full px-6 py-3 tracking-[-0.3px] leading-[24px] font-normal">
                        Try For Free
                    </div>
                    </a>
                </div>
            </div>
        </>
    );
}
