import { useState } from "react";

export default function Pricing() {
    const [locations, setLocations] = useState(1);

    function handleChange() {
        setLocations(document.getElementById("inputt").value);
    }
    return (
        <div id="pricing">
            <div className="text-center mt-36  md:mt-36 mb-16 px-6">
                {/* <p className="sm:text-[18px] text-dprpl font-bold leading-5 tracking-[1.8px]">
                    PRICING
                </p> */}
                <p className="text-4xl sm:text-5xl font-bold sm:leading-[60px] text-[#170F49] mt-4 sm:mt-7">
                    Plans & Pricing
                </p>
                <p className="text-[#6F6C90] font-[18px] leading-[30px] mt-3 max-w-[600px] mx-auto">
                    Explore the perfect fit for your needs with POS44's transparent Plans & Pricing.
                    Tailor your success at a cost that aligns with your goals.
                </p>
            </div>
            <div className="text-center mb-20 text-[18px] text-[#170F49]">
                How many locations you have?{" "}
                <span className="inline-block mt-5 sm:mt-0">
                    <input
                        id="inputt"
                        type="number"
                        min="1"
                        placeholder="1"
                        className="w-10 mr-3 smPlus:mx-5 rounded-lg border border-solid border-[#D9DBE9] text-center text-2xl"
                        onChange={handleChange}
                    />{" "}
                    <span className="font-bold">locations</span>
                </span>
            </div>
            <div className="max-w-[1200px] mx-auto flex justify-center gap-6 flex-wrap">
                <div className="w-full sm:w-[380px] mx-4 sm:mx-0 p-8 sm:p-10 rounded-[30px] border border-solid border-[#EFF0F6] shadow-sm">
                    <div className="flex">
                        <img src="picon1.svg" alt="" />
                        <div className="ml-[18px] mt-[6px]">
                            <p className="text-[18px] text-[#6F6C90] font-medium leading-5">
                                1 Month
                            </p>
                            <p className="text-[24px] text-[#170F49] font-bold leading-[35px]">
                                Plan A
                            </p>
                        </div>
                    </div>
                    <p className="text-[#6F6C90] sm:text-[18px] sm:leading-[30px] mt-[18px]">
                        budget-friendly choice packed with essential features.{" "}
                    </p>
                    <p className="mt-3 mb-4 text-[42px] sm:text-[54px] font-bold leading-[66px] text-[#170F49] tracking-wide">
                        ${locations * 89}
                    </p>
                    <p className="mb-6 font-bold text-[18px] leading-[20px] text-[#170F49]">
                        What's included
                    </p>
                    <div>
                        <div className="flex items-center mb-4">
                            <img src="check.svg" alt="" />
                            <p className="text-[18px] leading-[20px] ml-3 text-[#170F49]">
                                Unlimited Employees
                            </p>
                        </div>
                        <div className="flex items-center mb-4">
                            <img src="check.svg" alt="" />
                            <p className="text-[18px] leading-[20px] ml-3 text-[#170F49]">
                                Unlimited Pos
                            </p>
                        </div>
                        <div className="flex items-center mb-4">
                            <img src="check.svg" alt="" />
                            <p className="text-[18px] leading-[20px] ml-3 text-[#170F49]">
                                Tech Support
                            </p>
                        </div>
                        <div className="flex items-center mb-4">
                            <img src="check.svg" alt="" />
                            <p className="text-[18px] leading-[20px] ml-3 text-[#170F49]">
                                Additional Location: 10% off
                            </p>
                        </div>
                    </div>
                    <a href="https://pos44.tech/user/register" className="no-underline">
                        <div className="cursor-pointer mt-9 text-white text-[18px] bg-[#4A3AFF] font-bold rounded-full py-6 text-center">
                            Get Started
                        </div>
                    </a>
                </div>
                <div className="w-full sm:w-[380px] mx-4 sm:mx-0 p-8 sm:p-10 rounded-[30px] border border-solid border-[#EFF0F6] shadow-sm bg-[#4A3AFF]">
                    <div className="flex">
                        <img src="picon2.svg" alt="" />
                        <div className="ml-[18px] mt-[6px]">
                            <p className="text-[18px] text-[#EFF0F6] font-medium leading-5">
                                6 Months
                            </p>
                            <p className="text-[24px] text-white font-bold leading-[35px]">
                                Plan B
                            </p>
                        </div>
                    </div>
                    <p className="text-[#D9DBE9] sm:text-[18px] sm:leading-[30px] mt-[18px]">
                        Unlock advanced tools and supercharge your operations.{" "}
                    </p>
                    <p className="mt-3 mb-4 text-[42px] sm:text-[54px] font-bold leading-[66px] text-white tracking-wide">
                        ${locations * 499}
                    </p>
                    <p className="mb-6 font-bold text-[18px] leading-[20px] text-white">
                        What's included
                    </p>
                    <div>
                        <div className="flex items-center mb-4">
                            <img src="check.svg" alt="" />
                            <p className="text-[18px] leading-[20px] ml-3 text-[#D9DBE9]">
                                Unlimited Employees
                            </p>
                        </div>
                        <div className="flex items-center mb-4">
                            <img src="check.svg" alt="" />
                            <p className="text-[18px] leading-[20px] ml-3 text-[#D9DBE9]">
                                Unlimited Pos
                            </p>
                        </div>
                        <div className="flex items-center mb-4">
                            <img src="check.svg" alt="" />
                            <p className="text-[18px] leading-[20px] ml-3 text-[#D9DBE9]">
                                Tech Support
                            </p>
                        </div>
                        <div className="flex items-center mb-4">
                            <img src="check.svg" alt="" />
                            <p className="text-[18px] leading-[20px] ml-3 text-[#D9DBE9]">
                                Additional Location: 15% off
                            </p>
                        </div>
                    </div>
                    <a href="https://pos44.tech/user/register" className="no-underline">
                        <div className="cursor-pointer mt-9 bg-white text-[18px] text-[#4A3AFF] font-bold rounded-full py-6 text-center">
                            Get Started
                        </div>
                    </a>
                </div>
                <div className="w-full sm:w-[380px] mx-4 sm:mx-0 p-8 sm:p-10 rounded-[30px] border border-solid border-[#EFF0F6] shadow-sm">
                    <div className="flex">
                        <img src="picon1.svg" alt="" />
                        <div className="ml-[18px] mt-[6px]">
                            <p className="text-[18px] text-[#6F6C90] font-medium leading-5">
                                1 Year
                            </p>
                            <p className="text-[24px] text-[#170F49] font-bold leading-[35px]">
                                Plan C
                            </p>
                        </div>
                    </div>
                    <p className="text-[#6F6C90] sm:text-[18px] sm:leading-[30px] mt-[18px]">
                        This plan provides comprehensive features to elevate your operations.{" "}
                    </p>
                    <p className="mt-3 mb-4 text-[42px] sm:text-[54px] font-bold leading-[66px] text-[#170F49] tracking-wide">
                        Enterprise
                    </p>
                    <p className="mb-6 font-bold text-[18px] leading-[20px] text-[#170F49]">
                        What's included
                    </p>
                    <div>
                        <div className="flex items-center mb-4">
                            <img src="check.svg" alt="" />
                            <p className="text-[18px] leading-[20px] ml-3 text-[#170F49]">
                                Unlimited Employees
                            </p>
                        </div>
                        <div className="flex items-center mb-4">
                            <img src="check.svg" alt="" />
                            <p className="text-[18px] leading-[20px] ml-3 text-[#170F49]">
                                Unlimited Pos
                            </p>
                        </div>
                        <div className="flex items-center mb-4">
                            <img src="check.svg" alt="" />
                            <p className="text-[18px] leading-[20px] ml-3 text-[#170F49]">
                                Tech Support
                            </p>
                        </div>
                        <div className="flex items-center mb-4 opacity-0">
                            <img src="check.svg" alt="" />
                            <p className="text-[18px] leading-[20px] ml-3 text-[#170F49]">
                                Additional Location: $10
                            </p>
                        </div>
                    </div>
                    <a
                        href="https://book.ms/b/G109c665529ec48ddb3eed26112e52afe@44degnorth.tech"
                        className="no-underline"
                    >
                        <div className="cursor-pointer mt-9 text-white text-[18px] bg-[#4A3AFF] font-bold rounded-full py-6 text-center">
                            Talk To Us
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
}
