import Marquee from "react-fast-marquee";

export default function Middle() {
    return (
        <div>
            <div className="bg-[#000B28] text-white mt-48 md:mt-64 flex items-center justify-center py-20 px-4 lg:px-0">
                <div className="text-center mr-8">
                    <p className="text-[25px] lg:text-[50px] font-bold lg:leading-[62px] max-w-[722px]">
                        There is everything you need to systematize{" "}
                        <span className="bg-gradient-to-r from-dprpl to-prpl bg-clip-text text-transparent pr-1">
                            processes
                        </span>
                    </p>
                    <p className="max-w-[666px] text-[#A39DE9] text-[14px] lg:text-[18px] lg:leading-[30px] mt-3">
                        Empower your workflow with POS44 – where every tool and
                        feature converge to streamline and systematize your
                        processes effortlessly.
                    </p>
                </div>
                <img className="w-1/4" src="stack.svg" alt="" />
            </div>
            <div className="mt-20 text-[20px]">
                <Marquee className="marquee mb-3" pauseOnHover="true">
                    <p>Multi-warehouse management</p>
                    <p>e-Catalogue include</p>
                    <p>Landing page</p>
                    <p>Online ordering support</p>
                    <p>Delivery management</p>
                    <p>Customer loyalty system</p>
                    <p>Item photos</p>
                    <p>Receipt printing</p>
                </Marquee>
                <Marquee
                    className="marquee mb-3"
                    direction="right"
                    pauseOnHover="true"
                >
                    <p>Generate barcodes</p>
                    <p>Print labels</p>
                    <p>Contract templates</p>
                    <p>API interface to connect with other apps</p>
                    <p>Keeping records of goods according to standards</p>
                    <p>Revisions without stopping sales</p>
                    <p>FIFO or Average methods to valuate the stock</p>
                    <p>Acceptances, transfers and write-offs of goods</p>
                </Marquee>
                <Marquee
                    className="marquee mb-3"
                    pauseOnHover="true"
                    speed={100}
                >
                    <p> Serial Number Tracking</p>
                    <p>Grouping goods</p>
                    <p>Dimensional grid for clothes</p>
                    <p>Metric, weight and bulk goods</p>
                    <p>Creating varieties from sets</p>
                    <p>Postpone sale</p>
                    <p>Wholesale, retail and custom prices</p>
                    <p>Price-list</p>
                </Marquee>
                <Marquee
                    className="marquee mb-3"
                    pauseOnHover="true"
                    speed={70}
                    direction="right"
                >
                    <p>Customers database</p>
                    <p>Salesman bonus calculation</p>
                    <p>Adress storage of goods</p>
                    <p>Employee Shift Management</p>
                    <p>Financial management</p>
                    <p>Money accounts management</p>
                    <p>Cost analysis</p>
                    <p>Invoices</p>
                    <p>Instant saving of data</p>
                    <p>Simultaneous work from multiple devices</p>
                </Marquee>
            </div>
        </div>
    );
}
