export default function Contact() {
    return (
        <div id="contact" className="mt-24 md:mt-32 mb-24 md:mb-[128px]">
            <div className="text-center mb-16 px-6">
                {/* <p className="sm:text-[18px] text-dprpl font-bold leading-5 tracking-[1.8px]">
                    GET IN TOUCH
                </p> */}
                <p className="text-4xl smtext-5xl font-bold sm:leading-[60px] text-[#170F49] mt-4 sm:mt-7">
                    Contact
                </p>
                <p className="text-[#6F6C90] font-[18px] leading-[30px] mt-3 max-w-[500px] mx-auto">
                    Please feel free to reach out to us using the contact
                    information provided below.
                </p>
                <div className="mt-24">
                    {/* <div>
                        <p className="text-[20px] font-medium">
                            Call Us:{" "}
                            <span className="text-[#4C3CFF]">
                                +7 (708) 888 01 27
                            </span>
                        </p>
                        <p className="text-[20px] font-medium">
                            Customer Support:{" "}
                            <span className="text-[#4C3CFF]">
                                +7 (708) 888 01 27
                            </span>
                        </p>
                    </div> */}
                    <div className="">
                        <p className="flex justify-center flex-wrap gap-4">
                            <div className="flex flex-wrap items-center justify-center space-x-4 gap-2">
                                <p>For sales and general inquries</p>
                                <a
                                    href="https://44degnorth.tech/contact/"
                                    className="block no-underline bg-slate-200 text-black rounded-lg p-2"
                                >
                                    Get In Touch
                                </a>
                                <p>&</p>
                            </div>
                            <div className="flex flex-wrap items-center justify-center space-x-4 gap-2">
                                <p>For support</p>
                                <a
                                    className="block no-underline bg-slate-200 text-black rounded-lg p-2"
                                    href="mailto:Innovation@44degnorth.tech"
                                >
                                    Innovation@44degnorth.tech
                                </a>
                            </div>
                        </p>
                    </div>
                    {/* <div className="flex items-center max-w-[370px] gap-8 mx-auto my-16 ">
                        <div className="bg-[#9393934D] w-full h-[1px]"></div>
                        <span className="text-[#9393938e]">or</span>
                        <div className="bg-[#9393934D] w-full h-[1px]"></div>
                    </div> */}
                    {/* <h1>For Support Email At</h1>
                    <a
                        className="no-underline text-2xl inline-block mt-4"
                        href="mailto:Innovation@44degnorth.tech"
                    >
                        Innovation@44degnorth.tech
                    </a> */}
                    {/* <div className="text-start bg-[#F9F9F9] rounded-[30px] border border-solid border-[#e3e3e3] p-8 max-w-[500px] mx-auto">
                        <p className="text-[20px] font-medium">
                            Leave an inquiry
                        </p>
                        <p className="text-[#818286] mt-3 ">
                            Please feel free to reach out to us using the
                            contact information provided below.
                        </p>
                        <div className="mt-10">
                            <input
                                placeholder="Your location"
                                type="text"
                                className="w-full rounded-[30px] h-16 border border-solid border-[#e3e3e3] pl-6 mb-3"
                            />
                            <input
                                placeholder="Your phone"
                                type="text"
                                className="w-full rounded-[30px] h-16 border border-solid border-[#e3e3e3] pl-6 mb-10"
                            />
                            <div className="cursor-pointer w-full h-16 bg-[#4A3AFF] rounded-[15px] flex items-center justify-center text-white font-medium text-lg">
                                Send
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}
