import { TypeAnimation } from "react-type-animation";
export default function Hero() {
    return (
        <div className="mt-36 sm:mt-44 text-center">
            <div className="text-[12px] sm:text-[16px] mx-auto w-max px-3 sm:px-5 py-1 sm:py-2 rounded-full bg-gradient-to-r from-[#FADE15] to-[#FAC815] tracking-[0.68px] sm:leading-[16px] uppercase">
                Simple inventory management application
            </div>
            <p className="text-5xl smPlus:text-6xl font-semibold font-head tracking-[-2.76px] mt-9 sm:text-7xl">
                Manage your business{" "}
                <span className="font-head bg-gradient-to-r from-[#0038FF] to-prpl bg-clip-text text-transparent pr-1">
                    easily
                </span>
            </p>
            <p className="tracking-[-0.3px] leading-[24px] text-blck mt-10 px-5 sm:px-0">
                <TypeAnimation
                    sequence={[
                        // Same substring at the start will only be typed out once, initially
                        "Clear accounting of goods, finances, sales, warehouse, customers and deliveries",
                    ]}
                    wrapper="p"
                    speed={50}
                    style={{ display: "inline-block" }}
                    repeat={1}
                    cursor={false}
                />
            </p>
            {/* <p className="tracking-[-0.3px] leading-[24px] text-blck mt-10 px-5 sm:px-0">
                Clear accounting of goods, finances, sales, warehouse, customers
                and deliveries
            </p> */}
            <div className="flex items-center w-max mx-auto mt-10">
            <a href="https://pos44.tech/user/register" className="no-underline">
                
                <div className="cursor-pointer transition-all duration-500 bg-gradient-to-r from-prpl to-dprpl bg-size-100 bg-pos-0 hover:bg-size-200 hover:bg-pos-100 text-white rounded-full px-6 py-3 tracking-[-0.3px] leading-[24px] font-normal">
                    Try For Free
                </div>
                </a>
                <a
                    href="#pricing"
                    className="px-6 py-3 tracking-[-0.3px] leading-[24px] font-normal text-dprpl no-underline"
                >
                    Plans & Pricing
                </a>
            </div>
            <div className="relative">
                <img src="mesh-grad.svg" alt="" className="w-full" />
                <img
                    src="dashboard.svg"
                    alt=""
                    className="dash w-[90%] lg:w-full max-w-[1000px] absolute left-1/2 -translate-x-1/2 transition-all duration-1000 pointer-events-none"
                />
            </div>
        </div>
    );
}
