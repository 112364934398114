import "./App.css";
import Navbar from "./components/Navbar";
import Hero from "./pages/Hero";
import Onboard from "./pages/Onboard";
import Features from "./pages/Features";
import Pricing from "./pages/Pricing";
import Middle from "./pages/Middle";
import Contact from "./pages/Contact";
import Footer from "./pages/Footer";
import { Helmet } from "react-helmet";

export default function App() {
    return (
        <>
            {/* <Helmet>
                <script>
                    {`
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '1390797634860239');
                fbq('track', 'PageView');
                `}
                </script>
                <noscript>
                    {`
                    <img
                        height="1"
                        width="1"
                        style="display:none"
                        src="https://www.facebook.com/tr?id=1390797634860239&ev=PageView&noscript=1"
                    />
                    `}
                </noscript>
            </Helmet> */}
            <div className="max-w-[1440px] mx-auto ">
                {/* <div className="fixed bg-dprpl text-white rounded-full w-16 h-16 flex justify-center items-center bottom-10 right-10">
                X
            </div> */}
                <Navbar />
                <Hero />
                <Onboard />
                <Features />
                <Middle />
                <Pricing />
                <Contact />
                <Footer />
            </div>
        </>
    );
}
