export default function Footer() {
    return (
        <div className="px-6 lg:px-0 mb-20 max-w-[1120px] mx-auto">
            <div className="w-full h-[1px] bg-[#E6E6E6] mx-auto mb-8"></div>
            <div className="flex flex-col md:flex-row justify-between items-center">
                {/* <p className="text-[#131316] text-[37px] font-bold leading-[72px] tracking-[-1.11px]">
                    POS44
                </p> */}
                <a className="no-underline text-black" href="https://44degnorth.tech">
                    {/* <img className="-ml-6" src="umb.png" alt="" /> */}
                    <p className="text-gray-500 hover:underline">
                        2024 ©️ 44degNorth Technologies Inc
                    </p>
                </a>
                <div className="flex items-center justify-center gap-6 mt-5 sm:mt-0">
                    <a
                        className="no-underline text-black"
                        href="https://www.facebook.com/pos44tech"
                    >
                        <img className="h-6" src="fb.png" alt="" />
                    </a>
                    <a
                        className="no-underline text-black"
                        href="https://www.instagram.com/pos44tech"
                    >
                        <img className="h-6" src="insta.webp" alt="" />
                    </a>
                </div>
                <div className="flex gap-5 md:gap-6 flex-wrap justify-center md:justify-end mt-5 sm:mt-0">
                    <a
                        className="no-underline text-black hover:underline"
                        href="https://44degnorth.tech/about-us/"
                    >
                        About Us
                    </a>
                    <a
                        className="no-underline text-black hover:underline"
                        href="https://44degnorth.tech/privacy-policy/"
                    >
                        Privacy Policy
                    </a>
                    <a
                        className="no-underline text-black hover:underline"
                        href="https://44degnorth.tech/terms-conditions/"
                    >
                        Terms & Conditions
                    </a>
                    {/* <a
                        className="text-end text-[14px]f font-semibold tracking-[1.25px] leading-6 text-[#080808] no-underline"
                        href=""
                    >
                        MAIN
                    </a>
                    <a
                        className="text-end text-[14px]f font-semibold tracking-[1.25px] leading-6 text-[#080808] no-underline"
                        href="#contact"
                    >
                        SUPPORT
                    </a>
                    <a
                        className="text-end text-[14px]f font-semibold tracking-[1.25px] leading-6 text-[#080808] no-underline"
                        href="#features"
                    >
                        FEATURES
                    </a>
                    <a
                        className="text-end text-[14px]f font-semibold tracking-[1.25px] leading-6 text-[#080808] no-underline"
                        href="#pricing"
                    >
                        PRICING
                    </a>
                    <a
                        className="text-end text-[14px]f font-semibold tracking-[1.25px] leading-6 text-[#080808] no-underline"
                        href="#contact"
                    >
                        CONTACTS
                    </a> */}
                </div>
            </div>
            {/* <div className="mt-8 flex justify-between">
                <div className="flex space-x-5 text-gray-500">
                    <p>Instagram</p>
                    <p>Facebook</p>
                </div>
                
            </div> */}
            <div className="w-full h-[1px] bg-[#E6E6E6] mx-auto my-8"></div>
            <div className="flex flex-col md:flex-row justify-between items-center gap-2 sm:gap-0">
                {/* <img className="-ml-6" src="umb.png" alt="" /> */}
                {/* <div className="flex gap-6 flex-wrap">
                    <p className="">Facebook</p>
                    <p className="">Instagram</p>
                    <p className="">Twitter</p>
                </div> */}
                <div className="w-full flex gap-2 md:gap-6 flex-wrap justify-start text-[#08080899] text-[14px] leading-[24px] tracking-[-0.09px]">
                    <div className="flex gap-2 md:gap-6">
                        {/* <a
                            className="no-underline text-black"
                            href="https://www.linkedin.com/company/44degnorth/"
                        >
                            Linkedin
                        </a>
                        <a
                            className="no-underline text-black"
                            href="mailto:Innovation@44degnorth.tech"
                        >
                            Innovation@44degnorth.tech
                        </a>
                        <a
                            className="no-underline text-black"
                            href="44degnorth.tech"
                        >
                            44degnorth.tech
                        </a> */}
                    </div>
                    {/* <div className="flex gap-2 md:gap-6">
                        <a
                            className="no-underline text-black"
                            href="https://44degnorth.tech/about-us/"
                        >
                            About Us
                        </a>
                        <a
                            className="no-underline text-black"
                            href="https://44degnorth.tech/privacy-policy/"
                        >
                            Privacy Policy
                        </a>
                        <a
                            className="no-underline text-black"
                            href="https://44degnorth.tech/terms-conditions/"
                        >
                            Terms & Conditions
                        </a>
                    </div> */}
                </div>
            </div>
        </div>
    );
}
