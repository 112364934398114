export default function Onboard() {
    return (
        <div className="mt-64 md:mt-96 text-center">
            <p className="text-2xl lg:text-4xl lg:leading-[52px] tracking-[-0.74px] max-w-[1048px] mx-auto px-8 lg:px-0">
                POS44 includes everything you need for effective sales, orders,
                stock, finances and deliveries management, in a simple and
                effortless form. Unleash the potential of your business
            </p>
            <p className="text-sm lg:text-xl lg:leading-7 tracking-[-0.4px] text-lightprpl max-w-[800px] mx-auto mt-9 px-10 lg:px-0">
                Elevate Your Business Efficiency – Sales, Orders, Stock,
                Finances, Deliveries – Seamlessly Managed, Effortlessly
                Thriving!
            </p>
            <div className="flex flex-wrap gap-6 justify-center lg:justify-between max-w-[1200px] mx-auto mt-36">
                <div className="relative flex flex-col justify-between w-[326px] h-[370px] p-10 rounded-[50px] bg-bgprpl text-start">
                    <img
                        src="arrow.svg"
                        alt=""
                        className="hidden xl:block absolute left-[248px] top-[75px]"
                    />
                    <div>
                        <img
                            src="icon1.svg"
                            alt=""
                            className="rounded-[30px]"
                        />
                    </div>
                    <div>
                        <p className="text-2xl font-bold mb-6">Convenient</p>
                        <p className="leading-6 text-fadedblck">
                            POS44: Simple, Convenient for All – From Beginners
                            to Experts, Understandable in Daily Work.
                        </p>
                    </div>
                </div>
                <div className="relative flex flex-col justify-between w-[326px] h-[370px] p-10 rounded-[50px] bg-bgprpl text-start drop-shadow-lg shadow-sm shadow-dprpl">
                    <img
                        src="arrow2.svg"
                        alt=""
                        className="hidden xl:block absolute left-[280px] top-[197px]"
                    />
                    <div>
                        <img
                            src="icon2.svg"
                            alt=""
                            className="rounded-[30px] drop-shadow-lg shadow-sm shadow-dprpl"
                        />
                    </div>
                    <div>
                        <p className="text-2xl font-bold mb-6">Effective</p>
                        <p className="leading-6 text-fadedblck">
                            POS44, Where Effectiveness is Elevated to Propel
                            Your Business Forward
                        </p>
                    </div>
                </div>
                <div className="flex flex-col justify-between w-[326px] h-[370px] p-10 rounded-[50px] bg-bgprpl text-start">
                    <div>
                        <img
                            src="icon3.svg"
                            alt=""
                            className="rounded-[30px]"
                        />
                    </div>
                    <div>
                        <p className="text-2xl font-bold mb-6">Scalable</p>
                        <p className="leading-6 text-fadedblck">
                            Scale your business to multiple locations and manage
                            stock in a single cloud space
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
